import App from './App.vue';
import i18n from './i18n';
import { piniaPluginErrorNotifier, piniaPluginLoadingState } from './plugins';
import { router } from './router';
import { ASSISTANT_SERVICE } from '@/@core/services/api/AssistantService';
import { hashString } from '@/@core/utils';
import { useNotificationStore } from '@/stores/useNotificationStore';
import { useUserStore } from '@/stores/userStore';
import AADesignSystem from '@aleph-alpha/ds-components-vue';
import { initialize } from '@lengoo/ui-core-service-analytics';
import { initFeatureFlag, useFeatureFlag } from '@lengoo/ui-core-service-feature-flag';
import '@unocss/reset/tailwind.css';
import { createPinia } from 'pinia';
import { createPersistedState } from 'pinia-plugin-persistedstate';
import 'virtual:uno.css';
import { createApp } from 'vue';

const { VITE_APP_ANALYTICS_WRITE_KEY } = import.meta.env;

export const app = createApp(App);

await initFeatureFlag(ASSISTANT_SERVICE.featureFlags());

const shouldEnableAnalytics = false;

initialize({
  writeKey: VITE_APP_ANALYTICS_WRITE_KEY,
  enabled: shouldEnableAnalytics,
});

app.use(i18n);
// @ts-expect-error AADesignSystem is a plugin, but it's not typed as such in the package.
app.use(AADesignSystem);

if (!useFeatureFlag('enableHomepage', false) && useFeatureFlag('enableAlternateHome', false)) {
  router.removeRoute('Homepage');
  router.addRoute('App', {
    path: '/',
    name: 'AlternateHome',
    component: () => import('@/modules/AlternateHome/views/AlternateHomeView.vue'),
  });
}

app.use(router);

const pinia = createPinia();
pinia.use(
  createPersistedState({
    key: (id) => {
      const userStore = useUserStore();
      let prefix = hashString('assistantuicache', true);
      if (userStore.user !== null) {
        prefix = hashString(userStore.user.profile.email, true);
      }
      return `${prefix}-${id}`;
    },
  })
);
pinia.use(piniaPluginLoadingState);
pinia.use(piniaPluginErrorNotifier(useNotificationStore, i18n.global));
app.use(pinia);

app.mount('#app');
