import { ApiError } from '@/@core/models/api/errors';
import { Response } from '@lengoo/ui-core-service-http';
import { HTTPError, KyResponse } from 'ky';

// TODO: adapt types after moving to ky only
export async function handleRequestError<R, T extends KyResponse<R> | Response<R>>(
  makeRequestCallback: () => Promise<T>
): Promise<T> {
  try {
    return await makeRequestCallback();
  } catch (error: unknown) {
    if (error instanceof HTTPError) {
      throw new ApiError(error.message, { cause: error.cause });
    }
    throw new ApiError();
  }
}
