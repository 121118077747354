import { z } from 'zod';

export const FEATURE_FLAGS_RESPONSE_SCHEMA = z.object({
  enableHomepage: z.boolean().optional(),
  enableAlternateHome: z.boolean().optional(),
  enableCockpit: z.boolean().optional(),
  enableSummarize: z.boolean().optional(),
  enableSearch: z.boolean().optional(),
  enableQa: z.boolean().optional(),
  enableTellJoke: z.boolean().optional(),
  enableTranslate: z.boolean().optional(),
  enableTranscribe: z.boolean().optional(),
  enableUsecases: z.boolean().optional(),
});

export type FeatureFlagsResponse = z.infer<typeof FEATURE_FLAGS_RESPONSE_SCHEMA>;
