import {
  TRANSCRIPTION_UPLOAD_FILE_RESPONSE_SCHEMA,
  TranscriptionUploadFileRequest,
  TranscriptionUploadFileResponse,
} from '@/@core/models/api/transcription';
import { HTTP_CLIENT } from '@/@core/services/api/httpClient';
import { handleRequestError } from '@/@core/services/api/utils/errorHandling';
import { validateResponseData } from '@/@core/services/api/utils/validateResponseData';
import { Http } from '@lengoo/ui-core-service-http';

export class TranscriptionService {
  constructor(readonly httpClient: Http) {}

  async uploadFile(
    requestUrl: string,
    request: TranscriptionUploadFileRequest
  ): Promise<TranscriptionUploadFileResponse> {
    const formData = new FormData();
    Object.entries(request).forEach(([key, value]) => {
      formData.append(key, value);
    });
    const response = await handleRequestError(
      async () =>
        await this.httpClient.post<void>(`files`, {
          baseURL: requestUrl,
          body: formData,
        })
    );
    return validateResponseData(response, TRANSCRIPTION_UPLOAD_FILE_RESPONSE_SCHEMA);
  }
}

export const TRANSCRIPTION_SERVICE = new TranscriptionService(HTTP_CLIENT);
