import { useQaResourcesStore } from '@/modules/Qa/stores/useQaResourcesStore';
import { translationRoute } from '@/modules/Translation/route';
import { useTranslationResourcesStore } from '@/modules/Translation/stores/useTranslationResourcesStore';
import { useTranscriptionStore } from '@/modules/transcription/stores/useTranscriptionStore';
import { homeRoute } from '@/router/index';
import { useConfigStore } from '@/stores/useConfigStore';
import { useFeatureFlag } from '@lengoo/ui-core-service-feature-flag';
import { NavigationGuard } from 'vue-router';

export async function withQaResources() {
  await useQaResourcesStore().getQaResources();
}

export async function withTranslationResources() {
  await useTranslationResourcesStore().getTranslationResources();
}

export async function withTranscriptionResources() {
  await useTranscriptionStore().initialize();
}

export async function withConfig() {
  const configStore = useConfigStore();
  if (!configStore.config) {
    await configStore.getConfig();
  }
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const redirectToTranslationRouteIfHomepageDisabled: NavigationGuard = (to, from) => {
  if (
    to.path === homeRoute.path &&
    !useFeatureFlag('enableHomepage', false) &&
    !useFeatureFlag('enableAlternateHome', false)
  ) {
    return { path: translationRoute.path };
  }
};
