import { homeRoute } from '@/router';
import { useUserStore } from '@/stores/userStore';
import { UserManager, type UserManagerSettings } from 'oidc-client-ts';
import { NavigationGuard } from 'vue-router';

if (!import.meta.env.VITE_IAM_ISSUER) {
  throw new Error('VITE_IAM_ISSUER is not defined');
}

if (!import.meta.env.VITE_IAM_CLIENT_ID) {
  throw new Error('VITE_IAM_CLIENT_ID is not defined');
}

/* eslint-disable @typescript-eslint/naming-convention */
const settings = {
  scope: 'openid email profile',
  loadUserInfo: true,
  authority: import.meta.env.VITE_IAM_ISSUER,
  client_id: import.meta.env.VITE_IAM_CLIENT_ID,
  redirect_uri: `${window.origin}/callback`,
  post_logout_redirect_uri: window.origin,
} as const satisfies UserManagerSettings;
/* eslint-enable @typescript-eslint/naming-convention */

export const authManager = new UserManager(settings);

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const AUTH_GUARD: NavigationGuard = async (to, from) => {
  if (!to.matched.some((record) => record.meta.requiresAuth)) return;

  const userStore = useUserStore();
  await userStore.fetch();

  if (userStore.isInvalidUserSession()) {
    userStore.user = null;
    await authManager.signinRedirect();
  }
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const AUTH_CALLBACK_GUARD: NavigationGuard = async (to, from) => {
  if ('state' in to.query) {
    await authManager.signinCallback(window.location.href);
    const userStore = useUserStore();
    await userStore.fetch();
  }
  return { path: homeRoute.path }; // redirects to app route if users accesses /callback manually
};
