import { z } from 'zod';

const WORKBOOK_RESPONSE_ITEMS_SCHEMA = z.object({
  id: z.number(),
  name: z.string(),
  content: z.string().optional(),
});

export const WORKBOOK_RESPONSE_SCHEMA = z.object({
  id: z.number(),
  name: z.string(),
  description: z.string().optional(),
  items: z.array(WORKBOOK_RESPONSE_ITEMS_SCHEMA).optional(),
});

const WORKBOOK_REQUEST_ITEMS_SCHEMA = z.object({
  id: z.number().optional(),
  name: z.string(),
  content: z.string().optional(),
});

export const WORKBOOK_REQUEST_SCHEMA = z.object({
  id: z.number().optional(),
  name: z.string(),
  description: z.string().optional(),
  items: z.array(WORKBOOK_REQUEST_ITEMS_SCHEMA),
});

export const WORKBOOKS_RESPONSE_ARRAY_SCHEMA = z.array(WORKBOOK_RESPONSE_SCHEMA);

export type WorkbookRequest = z.infer<typeof WORKBOOK_REQUEST_SCHEMA>;

export type WorkbookRequestItem = z.infer<typeof WORKBOOK_REQUEST_ITEMS_SCHEMA>;

export type WorkbookResponse = z.infer<typeof WORKBOOK_RESPONSE_SCHEMA>;

export type WorkbookResponseItem = z.infer<typeof WORKBOOK_RESPONSE_ITEMS_SCHEMA>;
