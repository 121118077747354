import { ResponseValidationError } from '@/@core/models/api/errors';
import { Response } from '@lengoo/ui-core-service-http';
import { KyResponse } from 'ky';
import { z } from 'zod';

export function validateResponseData<T extends z.ZodTypeAny>(
  response: Response<unknown>,
  schema: T
): z.infer<T> {
  const responseValidationResult = schema.safeParse(response.data);
  if (!responseValidationResult.success) {
    throw new ResponseValidationError(undefined, {
      cause: responseValidationResult.error.issues,
    });
  }

  return responseValidationResult.data as z.infer<T>;
}

export async function validateKyResponseData<T extends z.ZodTypeAny>(
  response: KyResponse<unknown>,
  schema: T
): Promise<z.infer<T>> {
  const text = await response.text();
  const responseValidationResult = schema.safeParse(text);
  if (!responseValidationResult.success) {
    throw new ResponseValidationError(undefined, {
      cause: responseValidationResult.error.issues,
    });
  }

  return responseValidationResult.data as z.infer<T>;
}
