import { z } from 'zod';

export interface TranscriptionUploadFileRequest {
  sourceData: File;
}

export const TRANSCRIPTION_UPLOAD_FILE_RESPONSE_SCHEMA = z.object({
  fileId: z.string(),
});
export type TranscriptionUploadFileResponse = z.infer<
  typeof TRANSCRIPTION_UPLOAD_FILE_RESPONSE_SCHEMA
>;

export const TRANSCRIPTION_DATA_STAGE_URL_RESPONSE_SCHEMA = z.object({
  url: z.string(),
});
export type TranscriptionDataStageUrlResponse = z.infer<
  typeof TRANSCRIPTION_DATA_STAGE_URL_RESPONSE_SCHEMA
>;

export const TRANSCRIPTION_JOB_STATUS_RESPONSE_SCHEMA = z.object({
  text: z.string(),
  segments: z.array(
    z.object({
      id: z.number(),
      start_time: z.number(),
      end_time: z.number(),
      text: z.string(),
    })
  ),
});

export type TranscriptionJobStatusResponse = z.infer<
  typeof TRANSCRIPTION_JOB_STATUS_RESPONSE_SCHEMA
>;

export interface TranscriptionJobStatusRequest {
  job_id: string;
}

export interface TranscriptionRequest {
  file_id: string;
}

export const TRANSCRIPTION_RESPONSE_SCHEMA = z.object({
  job_id: z.string(),
  status: z.string(),
});
export type TranscriptionJobResponse = z.infer<typeof TRANSCRIPTION_RESPONSE_SCHEMA>;
