import {
  CollectionResource,
  LanguageModelResource,
  UserCollectionResource,
} from '@/@core/models/api/resourcesResponse';
import { ASSISTANT_SERVICE } from '@/@core/services/api/AssistantService';
import { groupAndSortResources } from '@/modules/Resources/utils/groupAndSortResources';
import { defineStore } from 'pinia';
import { ref } from 'vue';

export const useQaResourcesStore = defineStore(
  'qaResources',
  () => {
    const collectionResources = ref<CollectionResource[] | undefined>(undefined);
    const languageModelResources = ref<LanguageModelResource[] | undefined>(undefined);
    const userCollectionResources = ref<UserCollectionResource[] | undefined>(undefined);

    const getQaResources = async () => {
      try {
        const resources = await ASSISTANT_SERVICE.qaResources();
        const resourcesGroupedAndSorted = groupAndSortResources(resources);
        collectionResources.value = resourcesGroupedAndSorted.Collection ?? [];
        languageModelResources.value = resourcesGroupedAndSorted.LanguageModel ?? [];
        userCollectionResources.value = resourcesGroupedAndSorted.UserCollection ?? [];
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
      } catch (error) {
        // TODO: differentiate error types (AST-664)
        console.log('Failed to get qa resources');
        collectionResources.value = undefined;
        languageModelResources.value = undefined;
        userCollectionResources.value = undefined;
      }
    };

    return {
      collectionResources,
      languageModelResources,
      userCollectionResources,
      getQaResources,
    };
  },
  {
    persist: true,
  }
);
